import React, { useEffect } from "react";
import Button from "../../components/ui/button";

const LogInSignUp = () => {
  useEffect(() => {
    window.scrollTo(0, 0);

    // Dynamically load Stripe script
    const script = document.createElement("script");
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    

    
  
    // Append Stripe script to the body
    document.body.appendChild(script);

    // Cleanup: remove Stripe script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
    
  }, []);

  const cognitoDomain = 'auth.deltaclaims.ai';
  const clientId = '4lv3t219e5eb9barflut8vm5kf';
  const redirectUri = 'https://portal.deltaclaims.ai/callback';
  const responseType = 'code';  // For Authorization Code Grant
  const loginUrl = `https://${cognitoDomain}/login?response_type=${responseType}&client_id=${clientId}&redirect_uri=${redirectUri}`;
  const handleLogin = () => {
    window.location.href = loginUrl;
  };
 

  return (
    <div className="w-full bg-[#f3fff4] relative">
      <div className="w-full relative">
        <img
          alt="banner"
          src="/assets/images/common-banner.png"
          className="w-full relative h-[150px] lg:h-[550px]"
        />
        <div className="w-full p-4 absolute top-0 lg:top-[250px] left-0 right-0 mx-auto">
          <h1 className="text-2xl lg:text-[60px] font-['Montserrat'] leading-[70px] text-white inline-block relative">
            Log In / Sign Up
            <span className="font-bold relative ml-3 z-10">
              <img
                alt="line"
                src="/assets/images/blog/line-through.png"
                className="w-full absolute top-[-6px] lg:-top-[20px] left-0 z-[-1]"
              />
            </span>
          </h1>
        </div>
      </div>
      <div className="flex justify-center items-center mt-12">
        <Button
                    type={"button"}
                    text={"Log In"}
                    fill={true}
                    onClick={() => {
                      handleLogin();
                    }}
          />
      </div>
      <div className="w-full max-w-screen-xl mx-auto my-10 border-b-2 border-[#d9d9d9] py-10">
        <div className="w-full p-4">
          <h2 className="text-center text-xl lg:text-[22px] font-bold"></h2>
        </div>
        <div>
          <stripe-pricing-table pricing-table-id="prctbl_1QBL3B2Ne58wht27kXT0VqUr"
            publishable-key="pk_live_51Q6F292Ne58wht27e9FJGvY2mdjDLkyk17JosU5LNsZ37P7AfWaM5O3Ttt78ZJwgVzu4VtfIScun05oeVLGFxM5C00WKReOVMs">
          </stripe-pricing-table>
        </div>
      </div>
    </div>
  );
};

export default LogInSignUp;
